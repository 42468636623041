<template>
  <b-container fluid class="footer-actions">
    <div class="m-action-footer row">
      <div class="col-sm-3 col-3">
        <router-link to="/home">
          <img :src="homeBtn" />
          <span :class="{'active': home}" class="nav-text d-block">الخريط</span>
        </router-link>
      </div>
      <div class="col-sm-3 col-3">
        <router-link to="/stats">
          <img :src="statsBtn" width="25px" />
          <span :class="{'active': stats}" class="nav-text d-block">إحصائيات المدينة</span>
        </router-link>
      </div>
      <div class="col-3 col-sm-3">
        <router-link to="/saved">
          <img :src="savedBtn" width="25px" />
          <span :class="{'active': saved}" class="nav-text d-block">المفضلة</span>
        </router-link>
      </div>
      <div class="col-3 col-sm-3">
        <router-link to="/profile">
          <img :src="userBtn" width="25px" />
          <span :class="{'active': user}" class="nav-text d-block"> حساب</span>
        </router-link>
      </div>
     </div>
  </b-container>
</template>

<script>
export default {
  name: 'FooterMapActions',
  data() {
    return {
      pin: require("@/assets/images/icons/map_.svg"),
      pin_grey: require("@/assets/images/icons/map_grey.svg"),
      stat: require("@/assets/images/icons/stat.svg"),
      stat_grey: require("@/assets/images/icons/stat_grey.svg"),
      bookmark: require("@/assets/images/icons/bookmark.svg"),
      bookmark_grey: require("@/assets/images/icons/bookmark_grey.svg"),
      profile: require("@/assets/images/icons/user.svg"),
      profile_grey: require("@/assets/images/icons/user_grey.svg")
    }
  },
  computed: {
    home() {
      if (this.$route.name === 'home')
        return true
      return false
    },
    stats() {
      if (this.$route.name === 'stats')
        return true
      return false
    },
    saved() {
      if (this.$route.name === 'saved')
        return true
      return false
    },
    user() {
      if(this.$route.name === 'profile')
        return true
      return false
    },
    homeBtn() {
      if (this.home) return this.pin
      return this.pin_grey
    },
    statsBtn() {
      if (this.stats) return this.stat
      return this.stat_grey
    },
    savedBtn() {
      if (this.saved ) return this.bookmark
      return this.bookmark_grey
    },
    userBtn() {
      if (this.user) return this.profile
      return this.profile_grey
    }
  },
  components: {

  },
  methods: {

  }
}
</script>
