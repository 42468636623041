<template>
  <div class="action-btns">
    <!--
    <b-button v-if="$route.name == 'home'" :to="{name: 'landing'}" :class="{'primary-color': profile}" pill><img :src="homeFilled"></b-button>
    -->
    <b-button :to="{name: 'profile'}" :class="{'primary-color': profile}" pill><img :src="userIcon"> حسابي</b-button>
    <b-button :to="{name: 'saved'}" :class="{'action-color': bookmarked, 'primary-color': saved}" pill><img :src="saveIcon">المفضلة</b-button>
    <b-button :to="{name: 'stats'}" :class="{'primary-color': stats}" pill><img :src="statIcon">احصائيات المدينة</b-button>
    <b-button :to="{name: 'transactions'}" :class="{'primary-color': stats}" pill><img :src="statIcon">الصفقات</b-button>
    <!-- <b-button :to="{name: 'transactions'}" :class="{'primary-color': stats}" pill><img :src="statIcon">Real Estate statistic</b-button> -->
  </div>
</template>

<script>
export default {
  name: 'MapActions',
  data() {
    return {
      homeFilled: require('@/assets/images/icons/home_filled.svg')
    }
  },
  computed: {
    bookmarked() {
      if(Object.keys(this.favourite).length > 0 && this.favourite.constructor === Object) {
        if(this.favourite.plan_id === this.plot.plan_id && this.$route.name === 'favourite')
          return true
      }
      return false
    },
    saved() {
      if (this.$route.name === 'saved')
        return true
      return false
    },
    profile() {
      if (this.$route.name === 'profile')
        return true
      return false
    },
    stats() {
      if (this.$route.name === 'stats')
        return true
      return false
    },
    plot() {
      return this.$store.state.plan.plot
    },
    favourite() {
      return this.$store.state.plan.favourite
    },
    saveIcon() {
      if (this.bookmarked || this.saved)
        return require("@/assets/images/icons/bookmark_white.svg")
      return require("@/assets/images/icons/bookmark.svg")
    },
    userIcon() {
      if (this.profile)
        return require("@/assets/images/icons/user_border.svg")
      return require("@/assets/images/icons/user.svg")
    },
    statIcon() {
      if (this.stats)
        return require("@/assets/images/icons/stat_border.svg")
      return require("@/assets/images/icons/stat.svg")
    }
  },
  components: {

  },
  methods: {

  }
}
</script>
