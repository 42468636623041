<template>
  <div id="m-map-box">
    <div v-show="showNavigation" class="user-nav">
      <div class="user-header text-right">
       {{ $t(headerDescription) }}
        <img :src="logo" />
      </div>
      <router-view></router-view>
    </div>
    <mapbox
      :access-token="accessToken"
      :map-options="options"
      :nav-control="control"
      @map-zoomend="zoomend"
      @map-click:class-color="clicked"
      @map-mouseenter:class-color="mouseEntered"
      @map-mouseleave:class-color="mouseLeft"
    />
    <footer-map-actions />
    <div class="m-map-back">
      <router-link :to="{name: 'home'}"><b-icon icon="arrow-left-circle-fill"></b-icon></router-link>
    </div>
    <div class="m-map-actions">
     <b-container fluid>
       <b-row>
         <b-col>
           <map-actions />
         </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>


<script>
import Mapbox from 'mapbox-gl-vue'
import MapActions from '@/components/MapActions.vue'
import FooterMapActions from '@/components/FooterMapActions.vue'

export default {
title: "Mokhatat Web",
data() {
  return {
    logo: require("@/assets/logo_white.png"),
    showNavigation: false
   }
 },
 components: {
   Mapbox,
   MapActions,
   FooterMapActions
 },
 watch: {
   '$route.meta.sticky'(val) {
     this.showNavigation = val
   }
 },
 computed: {
   options() {
     return this.$store.state.options
   },
   accessToken() {
     return this.$store.state.accessToken
   },
   control() {
     return this.$store.state.control
   },
   searchState() {
     return this.$route.name
   },
   headerDescription() {
     if (this.$route.name == 'saved')
       return "favourite"

     if (this.$route.name == 'profile')
       return "my_account"
     return "stat"
   }
 },
 methods: {
   switchNav(value) {
     if (this.$route.meta.sticky === true) {
       this.showNavigation = true
       return
     }
     this.showNavigation = value
   },
   minNav() {
     this.showNavigation = false
   },
   zoomend(map) {
     let zoom = map.getZoom()
     this.$store.dispatch("changeZoom", zoom)
   },
   clicked(map, e) {
     const plotNo = e.features[0]["properties"]["Plot_No"]
     const planNo = e.features[0]["properties"]["Plan_No"]
     const plotId = this.$route.params.plotId
     const plan = this.$route.query.plan
     if(plotNo == plotId && planNo == plan) {
       this.showNavigation = true
       return
     }
     this.$router.push({path: "/plot/"+plotNo, query: {plan: planNo}})
   },
   mouseEntered(map) {
     map.getCanvas().style.cursor = 'pointer'
   },
   mouseLeft(map) {
     map.getCanvas().style.cursor = ''
   },
  },
  created() {
    this.showNavigation = this.$route.meta.sticky
  }
}
</script>

<style lang="scss">
</style>
